
import Vue from "vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
// import RadarChart from "./components/RadarChart.vue";
import KpiTable from "./components/KpiTable.vue";
import { addKpiCustomization } from "@/util/kpis";
import axios from "axios";

export default {
  components: { KpiTable },
  data() {
    return {
      lastTimeStamp: Date.now(),
      comparisionLoading: false,
      overViewKpiList: [],
      apiTimer: null,
      lastModifiedJsonData: {},
    };
  },
  computed: {
    ...mapState("detailedReport", ["kpiLineChart"]),
    ...mapGetters("tenant", ["hasResetAccount", "isNewAccount"]),
    ...mapState("overview", {
      overViewKpis: "kpis",
      regions: "regions",
      districts: "districts",
      stores: "stores",
      groups: "groups",
      SVs: "SVs",
    }),
    ...mapState("user", ["kpiPreferences", "kpiLabelValue"]),
    ...mapGetters("filter", [
      "getStoreCodesList",
      "getDaysOfWeekList",
      "getStartingPeriod",
      "getEndingPeriod",
      "getStartingCompared",
      "getEndingCompared",
      "getCustomCalenderState",
    ]),
  },
  watch: {
    kpiPreferences(newValue) {
      this.overViewKpiList = addKpiCustomization(this.overViewKpis);
    },
    getStartingPeriod: {
      handler() {
        this.refreshAction();
      },
      immediate: true,
    },
  },
  mounted() {
    Vue?.prototype?.$mixpanel?.track("detailed_report", {
      referrer: document.referrer,
    });
  },
  methods: {
    ...mapActions("comparison", ["fetchAllComparision"]),
    ...mapActions("weather", ["getWeather"]),
    ...mapActions("user", ["fetchKpiPreferences"]),
    ...mapMutations("detailedReport", ["setSelectedKPI", "setCompareKPI"]),
    ...mapActions("overview", ["fetchOverview", "setDataByLocationFormatKey"]),

    async refreshAction() {
      clearTimeout(this.apiTimer);
      this.apiTimer = setTimeout(async () => {
        try {
          await this.fetchKpiPreferences();
          this.lastTimeStamp = Date.now();
          await this.refreshOverview();
        } catch (error) {
          this.$q.notify({
            type: "negative",
            position: "top",
            message: `Failed to refresh: ${error.message}`,
          });
        }
      }, 500);
    },
    async refreshPage() {
      clearTimeout(this.apiTimer);
      this.apiTimer = setTimeout(this.refreshAction, 200);
    },
    refreshPageComparision(val) {
      this.setDataByLocationFormatKey({ key: val.value });
    },
    getParamsForOverview() {
      const stC = this.getStoreCodesList;
      if (!stC) return null;

      return {
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        compareStartDate: this.getStartingCompared,
        compareEndDate: this.getEndingCompared,
        storeCodes: stC,
        daysOfWeek: this.getDaysOfWeekList,
        useCustomCalender: this.getCustomCalenderState,
        groupByMetricKey: true,
        comparisonYKPIMetricKey: this.kpiLabelValue?.[0]?.value,
      };
    },
    async refreshOverview() {
      try {
        this.comparisionLoading = true;

        const params = this.getParamsForOverview();
        if (!params) {
          throw new Error("Invalid parameters for overview");
        }

        const isActionable = await this.fetchOverview(params);

        if (isActionable) {
          this.overViewKpiList = addKpiCustomization(this.overViewKpis);
        }

        this.comparisionLoading = false;
      } catch (error) {
        this.$q.notify({
          type: "negative",
          position: "top",
          message: `Overview update failed: ${error.message}`,
        });
      }
    },
    async calcLastTimeStamp() {
      try {
        const response = await axios.get(
          "https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/LastModifiedStore.json"
        );
        return response?.data || {};
      } catch (error) {
        this.$q.notify({
          type: "negative",
          position: "top",
          message: `Failed to fetch last timestamp: ${error.message}`,
        });
        return {};
      }
    },
  },
};
