
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { kpiMapping, kpiList } from '../../../util/mockData/kpiMapping';
import { filterAvailableKpis } from '@/util/kpis';
import PaginationMixin from '@/mixins/PaginationMixin';
import { weatherObj } from '../../../util/weatherIcons';
import moment from "moment";
export default {
  mixins: [PaginationMixin],
  data() {
    return {
      pagination: {
        rowsPerPage: 0
      },
      selectedKPIOptionList: [],
      loading: false,
      columns: [],
      data: [],
      formattedTableData: [],
      parameter: '',
      customSort: ['Date', 'Weather']
    };
  },
  computed: {
    ...mapState('weather', ['weather', 'weatherCompare']),
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'tabluarDataKpiOptions'
    ]),
    ...mapState('filter', ['tabluarDataKpiOptions']),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('distribution', ['byDate']),
    ...mapState('user', ['kpiPreferences'])
  },
  watch: {
    weather() {
      this.furtherHandler();
    },

    selectedKPIOptionList: {
      handler: 'loadData',
      immediate: true
    },
    tabluarDataKpiOptions(newValue) {
      this.selectedKPIOptionList = filterAvailableKpis(newValue);
    }
  },
  created() {
    this.kpiPreferences.map(kpi => {
      this.customSort.push(kpi.metricKey);
    });
    this.getLocalStorageTabularKPI();

    this.selectedKPIOptionList = filterAvailableKpis(
      this.tabluarDataKpiOptions
    );
  },
  methods: {
    ...mapActions('distribution', ['fetchDistributionByDate']),
    ...mapMutations('filter', ['getLocalStorageTabularKPI']),
    kpisorter(x, y) {
      return (
        this.customSort.indexOf(x.field) - this.customSort.indexOf(y.field)
      );
    },
    handleSearch(e) {
      // let filteredData = Object.keys(this.byDate).map(date => {
      //   return {
      //     [this.$t('Date')]: date,
      //     ...this.byDate[date]
      //   };
      // });

      this.data = this.formattedTableData.filter(tableData => {
        if (e.target.name === '天気' || e.target.name === 'Weather') {
          return tableData[e.target.name].Temperature.toLowerCase().includes(
            e.target.value.toLowerCase()
          );
        } else {
          return tableData[e.target.name]
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
      });
    },
    weatherSingleDate() {
      const tempObj = { temp: 0, tempMin: 0, tempMax: 0 };
      const iconCounts = {};
      const descriptionCounts = {};
      const key = this.weather[0];
      for (const obj in this.weather[0]) {
        tempObj.temp += key[obj].temp;
        tempObj.tempMax += key[obj].tempMax;
        tempObj.tempMin += key[obj].tempMin;
        // Count occurrences of icons and descriptions
        iconCounts[key[obj].icon] = (iconCounts[key[obj].icon] || 0) + 1;
        descriptionCounts[key[obj].description] =
          (descriptionCounts[key[obj].description] || 0) + 1;
      }
      // // Find the most common icon and description
      const mostCommonIcon = Object.keys(iconCounts).reduce((a, b) =>
        iconCounts[a] > iconCounts[b] ? a : b
      );
      const mostCommonDescription = Object.keys(
        descriptionCounts
      ).reduce((a, b) => (descriptionCounts[a] > descriptionCounts[b] ? a : b));
      // Calculate average temperature values
      tempObj.temp = tempObj.temp / 24;
      tempObj.tempMax = tempObj.tempMax / 24;
      tempObj.tempMin = tempObj.tempMin / 24;
      // icons
      tempObj.icon = mostCommonIcon;
      tempObj.description = mostCommonDescription;
      return [{ [this.getStartingPeriod]: tempObj }];
    },
    furtherHandler() {
      let lang = this.$store.state.user.locale;
      if (this.byDate) {
        const byDate = { ...this.byDate };
        const availableDate = Object.keys(byDate);

        if (
          availableDate &&
          availableDate.length &&
          Object.keys(byDate[availableDate[0]]).length
        ) {
          var weatherArr = [];
          if (this.getStartingPeriod == this.getEndingPeriod) {
            weatherArr = this.weatherSingleDate();
          } else {
            weatherArr = this.weather;
          }
          const tabularData = availableDate.map(singleDate => {
            const singleTableRow = { ...byDate[singleDate] };

            ['Weather', 'WeatherIcon', 'Temperature'].forEach(
              col => delete singleTableRow[col]
            );

            var date = singleDate?.replace(/\s*\(.*?\)\s*/g, '');

            var weather = weatherArr?.find(v => v.hasOwnProperty(date))?.[date];
            if (weather?.temp) {
              return {
                [this.$t('Date')]: moment(singleDate).locale(lang).format('ll') + ` (${this.getFullDay(singleDate)})`,
                [this.$t('weather')]: {
                  Temperature: weather?.temp.toFixed(2),
                  Weather: weather?.icon,
                  WeatherIcon: weatherObj[weather?.icon]
                },
                ...singleTableRow
              };
            } else {
              return {
                [this.$t('Date')]: singleDate,
                ...singleTableRow
              };
            }
          });

          const sortedKpis = this.kpiPreferences.map(k => k.title);
          let singleTabularData = Object.keys(tabularData[0]);
          singleTabularData.sort((a, b) =>
            sortedKpis.indexOf(a) > sortedKpis.indexOf(b) ? 1 : -1
          );
          if (singleTabularData.includes(this.$t('weather'))) {
            singleTabularData = [
              this.$t('Date'),
              this.$t('weather'),
              ...singleTabularData
            ];
          }
          var tabularColumn = Array.from(new Set(singleTabularData))
            .map(column => {
              return {
                name:
                  this.kpiPreferences.find(k => k.metricKey === column)
                    ?.title ?? this.$t(column),
                align: 'center',
                label:
                  this.kpiPreferences.find(k => k.metricKey === column)
                    ?.title ?? this.$t(column),
                field: column,
                sortable: true
              };
            })
            .sort(this.kpisorter);
          // // hide
          // if(!this.selectedKPIOptionList.includes('sales')){
          //   tabularColumn = tabularColumn.filter(v => v.name != 'Sales')
          //   tabularData.forEach(element => {
          //     delete element.sales
          //   });
          // }
          // //
          this.data = [...tabularData];
          this.columns = [...tabularColumn];
          this.formattedTableData = [...tabularData];
        }
      }
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t("Sun");
      }
      if (dayVal === 1) {
        return this.$t("Mon");
      }
      if (dayVal === 2) {
        return this.$t("Tue");
      }
      if (dayVal === 3) {
        return this.$t("Wed");
      }
      if (dayVal === 4) {
        return this.$t("Thu");
      }
      if (dayVal === 5) {
        return this.$t("Fri");
      }
      if (dayVal === 6) {
        return this.$t("Sat");
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.data = [];
        this.columns = [];

        var kpiString = this.selectedKPIOptionList.join(',');
        if(kpiString.includes('tra'))
        {
          kpiString += ',st';
          if(!kpiString.includes('sls'))
            kpiString += ',sls2';
        }

        console.log(kpiString);
        await this.fetchDistributionByDate({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          storeCodes: this.getStoreCodesList,
          daysOfWeek: this.getDaysOfWeekList,
          kpis: kpiString
        });
        this.furtherHandler();
      } catch (error) {
        // this.$q.notify({
        //   type: 'negative',
        //   position: 'top',
        //   message: this.$t('errorMsg')
        // });
      } finally {
        this.loading = false;
      }
    }
  }
};
